/* Custom Radio Group Styles */
.dss-custom-radio-group {
  --_dss-radio-gap-horizontal: var(--dss-spacing-600);
  --_dss-radio-gap-vertical: var(--dss-spacing-400);
  display: flex;
  gap: var(--_dss-radio-gap-horizontal);

  &-vertical {
    flex-direction: column;
    row-gap: var(--_dss-radio-gap-vertical);
  }

}


.dss-radio-wrapper {
  --_dss-radio-outer-circle-size: var(--dss-sizing-radiobutton-default-normal);
  // --_dss-radio-label-padding-left: calc(var(--_dss-radio-outer-circle-size) + var(--dss-spacing-radiobutton-default-inline-normal-choice-label));
  --_dss-radio-border-width: var(--dss-border-width-radiobutton-default-normal);
  --_dss-radio-border-radius: var(--dss-border-radius-radiobutton-default-full);
  --_dss-radio-border-color: var(--dss-color-border-radiobutton-default-inactive);
  --_dss-radio-border-hover: var(--dss-color-border-radiobutton-default-hovered);
  --_dss-radio-border-checked: var(--dss-color-border-radiobutton-default-pressed);
  --_dss-radio-border-disabled: var(--dss-color-border-radiobutton-default-disabled);

  --_dss-radio-background-disabled: var(--dss-color-background-radiobutton-default-disabled);
  --_dss-radio-background-color: var(--dss-color-background-radiobutton-default-inactive);
  --_dss-radio-background-hover: var(--dss-color-background-radiobutton-default-hovered);
  --_dss-radio-background-pressed: var(--dss-color-background-radiobutton-default-pressed);

  --_dss-radio-dot-color: var(--_dss-radio-background-pressed);

  --_dss-radio-text-disabled: var(--dss-color-text-radiobutton-default-disabled);
  --_dss-radio-text-color: var(--dss-color-text-radiobutton-default-active);
  --_dss-radio-text-hover: var(--dss-color-text-radiobutton-default-hovered);
  --_dss-radio-text-pressed: var(--dss-color-text-radiobutton-default-pressed);
  // --_dss-radio-text-font-size: var(--dss-font-radiobutton-default-uitext-normal-font-size);
  // --_dss-radio-text-font-weight: var(--dss-font-radiobutton-default-uitext-normal-font-weight);
  // --_dss-radio-text-line-height: var(--dss-font-radiobutton-default-uitext-normal-line-height);
  position: relative;
  display: flex;
  align-items: center;
  column-gap: var(--dss-spacing-radiobutton-default-inline-normal-choice-label);
  min-width: var(--_dss-radio-outer-circle-size);
  min-height: var(--_dss-radio-outer-circle-size);
  transition-property: border;

  .dss-radio-input {
    opacity: 0;
    // width: 0;
    width: var(--_dss-radio-outer-circle-size);
  }

  .dss-radio-label {
    display: inline-block;
    color: var(--_dss-radio-text-color);
    // padding-left: var(--_dss-radio-label-padding-left);
    // font-size: var(--_dss-radio-text-font-size);
    // font-weight: var(--_dss-radio-text-font-weight);
    // line-height: var(--_dss-radio-text-line-height);
    font: var(--dss-font-radiobutton-default-uitext-normal);
    cursor: pointer;
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    border-radius: var(--_dss-radio-border-radius);
    aspect-ratio: 1 / 1;
    transition: all .4s;
  }

  &::before {
    width: var(--_dss-radio-outer-circle-size);
    border: var(--_dss-radio-border-width) solid var(--_dss-radio-border-color);

  }

  &::after {
    top: 4px;
    left: 4px;
    width: calc(var(--_dss-radio-outer-circle-size) - var(--dss-spacing-radiobutton-default-inline-normal-choice-label));
    transform: scale(0);
    transition: all 0.4s cubic-bezier(0.57, -0.24, 0.27, 1.55);
  }

  // hover state
  &:hover {
    --_dss-radio-border-color: var(--_dss-radio-border-hover);
    --_dss-radio-background-color: var(--_dss-radio-background-hover);

    .dss-radio-label {
      --_dss-radio-text-color: var(--_dss-radio-text-hover);
    }

  }

  // checked state
  &.checked {
    --_dss-radio-border-color: var(--_dss-radio-border-checked);
    --_dss-radio-background-color: var(--_dss-radio-background-pressed);

    .dss-radio-label {
      --_dss-radio-text-color: var(--_dss-radio-text-pressed);
    }

    &::after {
      background-color: var(--_dss-radio-dot-color);
      transform: scale(1);
    }
  }


  &.disabled {
    --_dss-radio-border-checked: var(--_dss-radio-border-disabled);
    --_dss-radio-dot-color: var(--_dss-radio-background-disabled);
    --_dss-radio-text-color: var(--_dss-radio-text-disabled);
    --_dss-radio-border-color: var(--_dss-radio-border-disabled);
    pointer-events: none;
    .dss-radio-label {
      --_dss-radio-text-color: var(--_dss-radio-text-disabled);
    }
  }

}

.dss-radio-outlined {
  --_dss-radio-outer-circle-size: var(--dss-sizing-radiobutton-outline-normal);
  --_dss-radio-border-radius: var(--dss-border-radius-radiobutton-outline-full);
  --_dss-radio-border-width: var( --dss-border-width-radiobutton-outline-normal-default);
  column-gap: var(--dss-spacing-radiobutton-outline-inline-normal-choice-label);
  --_dss-radio-text-disabled: var(--dss-color-text-radiobutton-outline-disabled);
  --_dss-radio-text-color: var(--dss-color-text-radiobutton-outline-active);
  --_dss-radio-text-hover: var(--dss-color-text-radiobutton-outline-hovered);
  --_dss-radio-text-pressed: var(--dss-color-text-radiobutton-outline-pressed);
  --_dss-radio-background-disabled: var(--dss-color-background-radiobutton-outline-disabled);
  --_dss-radio-background-color: var(--dss-color-background-radiobutton-outline-inactive);
  --_dss-radio-background-hover: var(--dss-color-background-radiobutton-outline-hovered);
  --_dss-radio-background-pressed: var(--dss-color-background-radiobutton-outline-pressed);
  --_dss-radio-border-color: var(--dss-color-border-radiobutton-outline-inactive);
  --_dss-radio-border-hover: var(--dss-color-border-radiobutton-outline-hovered);
  --_dss-radio-border-checked: var(--dss-color-border-radiobutton-outline-pressed);
  --_dss-radio-border-disabled: var(--dss-color-border-radiobutton-outline-disabled);
  --_dss-radio-dot-color: var(--dss-color-background-radiobutton-outline-inactive);
  &.checked {
      .dss-radio-label {
        font: var(--dss-font-radiobutton-outline-uitext-normal);
      }

      &::before {
        border-width: var(--dss-border-width-radiobutton-outline-normal-pressed);
      }
  }
  &.disabled {
    --_dss-radio-dot-color: var(--dss-color-background-radiobutton-outline-inactive);
   
  }
}


.dss-custom-radio-group {
  &-xs {
    .dss-radio-wrapper {
      --_dss-radio-outer-circle-size: var(--dss-sizing-radiobutton-default-dense);
      --_dss-radio-border-width: var(--dss-border-width-radiobutton-default-dense);

      .dss-radio-label {
        font: var(--dss-font-radiobutton-default-uitext-dense);
      }

      &.dss-radio-outlined {
        --_dss-radio-outer-circle-size: var(--dss-sizing-radiobutton-outline-dense);
        .dss-radio-label {
          font: var(--dss-font-radiobutton-outline-uitext-dense);
        }

        &::before {
          --_dss-radio-border-width: var(--dss-border-width-radiobutton-outline-dense-default);
        }

        &.checked::before {
          --_dss-radio-border-width: var(--dss-border-width-radiobutton-outline-dense-pressed);
        }
      }


    }

  }


}
