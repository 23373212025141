.tooltip-container {
  --_tooltip-bg: var(--dss-color-background-tooltip-default);
  --_tooltip-radius: var(--dss-border-radius-tooltip-dense);
  --_tooltip-item-gap: var(--dss-spacing-tooltip-stack-dense-label-message);
  --_tooltip-padding: var(--dss-spacing-tooltip-inset-dense);
  --_tooltip-label-color: var(--dss-color-text-tooltip-primary);
  --_tooltip-label-font: var(--dss-font-tooltip-uitext-labeltext-dense);
  // --_tooltip-label-size: var(--dss-font-tooltip-uitext-label-text-normal-font-size);
  // --_tooltip-label-weight: var(--dss-font-tooltip-uitext-label-text-normal-font-weight);
  // --_tooltip-label-line-height: var(--dss-font-tooltip-uitext-label-text-normal-line-height);
  --_tooltip-text-color: var(--dss-color-text-tooltip-secondary);
  --_tooltip-text-font: var(--dss-font-tooltip-uitext-messagetext-dense);
  // --_tooltip-text-size: var(--dss-font-tooltip-uitext-message-text-normal-font-size);
  // --_tooltip-text-weight: var(--dss-font-tooltip-uitext-message-text-normal-font-weight);
  // --_tooltip-text-line-height: var(--dss-font-tooltip-uitext-message-text-normal-line-height);
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--_tooltip-label-color);
  font: var(--_tooltip-label-font);
  transition: all 0.35s;

  .tooltip {
    --tooltip-arrow-size: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: var(--_tooltip-item-gap);
    width: auto;
    background-color: var(--_tooltip-bg);
    border-radius: var(--_tooltip-radius);
    padding: var(--_tooltip-padding);
    position: absolute;
    z-index: 1;
    bottom: calc(100% + var(--tooltip-arrow-size) + 1px);
    // left: 50%;
    // transform: translateX(-50%);
    transition: all 0.4s;

    &::before {
      content: "";
      position: absolute;
      top: 100%;
      border-width: calc(var(--tooltip-arrow-size) + 1px);
      border-style: solid;
      border-color: var(--dss-color-background-tooltip-default) transparent transparent transparent;
      margin-inline: -1px;
    }

    &-label {
      color: var(--_tooltip-label-color);
      font: var(--_tooltip-label-font);
    }

    &-text {
      color: var(--_tooltip-text-color);
      font: var(--_tooltip-text-font);
      text-wrap: nowrap;
      text-transform: capitalize;

    }

    &-wrap {
      .tooltip-text {
        text-wrap: wrap !important;
        min-width: 223px;

      }
    }

    &-large {
      --_tooltip-padding: var(--dss-spacing-tooltip-inset-normal);
      --_tooltip-radius: var(--dss-border-radius-tooltip-normal);
      --_tooltip-item-gap: var(--dss-spacing-tooltip-stack-normal-label-message);
      --_tooltip-label-font: var(--dss-font-tooltip-uitext-labeltext-normal);
      --_tooltip-text-font: var(--dss-font-tooltip-uitext-messagetext-normal);
    }

  }
}

.plt {
  // --_tooltip-bg: var(--plt-background-info);
  --_tooltip-bg: var(--plt-background-inverse);
  --_tooltip-radius: var(--plt-border-radius-100);
  --_tooltip-item-gap: var(--plt-space-0);
  --_tooltip-padding: var(--plt-space-button-denser);
  --_tooltip-label-color: var(--plt-neutrals-400);
  --_tooltip-label-size: var(--plt-ui-text-xs-regular-font-size);
  --_tooltip-label-weight: var(--plt-ui-text-xs-regular-font-weight);
  --_tooltip-label-line-height: var(--plt-ui-text-xs-regular-line-height);
  --_tooltip-text-color: var(--plt-text-on-bg-fill);
  --_tooltip-text-size: var(--plt-ui-text-sm-regular-font-size);
  ;
  --_tooltip-text-weight: var(--plt-ui-text-sm-regular-font-weight);
  --_tooltip-text-line-height: var(--plt-ui-text-sm-regular-line-height);
  ;
  transition: all 0.35s;
  // position: relative;
  // display: inline-block;

  &:hover {
    .tooltip {
      visibility: visible;
    }
  }

  .tooltip {
    --tooltip-arrow-size: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--_tooltip-item-gap);
    // visibility: hidden;
    visibility: visible;
    width: auto;
    background-color: var(--_tooltip-bg);
    border-radius: var(--_tooltip-radius);
    padding: var(--_tooltip-padding);
    position: absolute;
    z-index: 1;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    transition: visibility 0.4s;

    &::before {
      content: "";
      position: absolute;
      top: 100%;
      // left: 50%;
      // margin-left: calc(var(--tooltip-arrow-size) * -1);
      border-width: var(--tooltip-arrow-size);
      border-style: solid;
      border-color: #171717 transparent transparent transparent;
    }

    &-label {
      width: max-content;
      color: var(--_tooltip-label-color);
      font-size: var(--_tooltip-label-size);
      font-weight: var(--_tooltip-label-weight);
      line-height: var(--_tooltip-label-line-height);
    }

    &-text {
      color: var(--_tooltip-text-color);
      text-wrap: nowrap;
      font-size: var(--_tooltip-text-size);
      font-weight: var(--_tooltip-text-weight);
      line-height: var(--_tooltip-text-line-height);

    }

    &-wrap {
      .tooltip-text {
        text-wrap: wrap !important;
        min-width: 223px;
      }
    }

    // &-small {
    //   --_tooltip-padding: var(--plt-space-button-denser);
    //   --_tooltip-radius: var(--plt-border-radius-100);
    //   --_tooltip-item-gap: var(--plt-space-0);
    //   --_tooltip-text-size: 12px;
    //   --_tooltip-text-line-height: 18px;
    // }

    &-large {
      --_tooltip-padding: var(--plt-space-300);
      --_tooltip-radius: var(--plt-border-radius-200);
      --_tooltip-item-gap: var(--plt-space-50);
      --_tooltip-text-size: var(--plt-font-size-200);
      --_tooltip-text-line-height: 19.5px;
    }
  }
}

.tooltip-container {
  .tooltip-top {
    bottom: calc(100% + var(--tooltip-arrow-size) + 1px);
  }

  .tooltip-bottom {
    top: calc(100% + var(--tooltip-arrow-size) + 1px);
    bottom: auto;

    &::before {
      top: auto;
      bottom: 100%;
      border-color: transparent transparent var(--dss-color-background-tooltip-default) transparent;
    }
  }

  .tooltip-right {
    bottom: auto;
    right: calc(-1 * var(--tooltip-arrow-size) - 1px);
    transform: translate(100%, 0);

    &::before {
      top: auto;
      left: -9px;
      border-color: transparent var(--dss-color-background-tooltip-default) transparent transparent;
    }
  }

  .tooltip-left {
    left: calc(-1 * var(--tooltip-arrow-size) - 1px);
    transform: translate(-100%, 0);
    bottom: auto;

    &::before {
      top: auto;
      left: 100%;
      border-color: transparent transparent transparent var(--dss-color-background-tooltip-default);
    }
  }

  .tooltip-arrow-none {
    &::before {
      content: none;
    }
  }

  .tooltip-btm-lft {
    &::before {
      left: 25%;
    }
  }

  .tooltip-btm-rgt {
    &::before {
      left: 75%;
    }
  }

  .tooltip-top-lft {
    &::before {
      left: 25%;
    }
  }

  .tooltip-top-rgt {
    &::before {
      left: 75%;
    }
  }

  .tooltip-lft-top {
    &::before {
      top: 25%;
    }
  }

  .tooltip-lft-btm {
    &::before {
      top: 65%;
    }
  }

  .tooltip-rgt-top {
    &::before {
      top: 25%;
    }
  }

  .tooltip-rgt-btm {
    &::before {
      top: 65%;
    }
  }
}
