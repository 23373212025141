.banner {
  position: relative;
  background-color: var(--dss-color-primary-600);
  background-repeat: no-repeat;
  background-size: cover;
  transition: background-color .35s;

  .plt-theme-pallette-item-light {
    color: #fff;
  }

  .plt-theme-pallette-item-dark {
    color: #000;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: var(--dss-color-primary-600);
    opacity: 0.6;
  }

  &.has-image::before {
    display: none;
  }

  .banner-header-wrapper {
    position: relative;
    padding: var(--plt-space-600) var(--plt-space-1000);
    // color: white !important;
    // border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  }

  .banner-head {
    position: relative;
    // color: var(--text-text-on-banner);
    // font-weight: var(--font-weight-700);
    // font-size: var(--font-size-900);
    // line-height: var(--line-height-100);
    padding-top: 113px;
    // color: var(--plt-text-text-on-banner);
  }

  .banner-desc {
    // position: relative;
    max-width: 675px;
    // color: var(--plt-text-text-on-banner);
    // color: var(--text-text-on-banner);
    // font-weight: var(--font-weight-400);
    // font-size: var(--font-size-400);
    // line-height: var(--line-height-140);
    // color: #fff;{
    padding-bottom: 12px;
  }

  .banner-edit-icon {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;

    .user-dropdown {
      top: 40px;
      right: 0;
    }
  }

  .banner-tab-wrapper {
    background: var(--plt-background-surface-transparent);

    .banner-tab-nav-links {
      list-style: none;
    }

    .home-header-button {
      margin-left: auto;
      border-radius: 0;
    }
  }

  .banner-tab-nav-item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    // color: var(--text-text-on-banner);
    border-top: 2px solid transparent;
    // border-right: 1px solid rgba(255, 255, 255, 0.5);
    border-right: 1px solid var(--plt-border-transparent);
    width: fit-content;

    &.active {
      border-top: 2px solid var(--dss-color-secondary-600);
      // border-top: 2px solid var(--plt-background-surface-selected);
      background: var(--plt-background-surface-transparent-active);
    }
  }

  .banner-tab-text {
    position: relative;
    display: flex;
    align-items: center;
    color: var(--plt-text-button-fill-primary);
    // font-weight: var(--font-weight-500);
    // font-size: var(--font-size-300);
    // line-height: var(--line-height-120);
    // border-bottom: 2px solid #5A0A8B;
  }

  .banner-tab-svg {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 20px;
    max-width: 20px;
  }
}

.floating-set {
  z-index: 99999;
  position: fixed;
  bottom: 52px;
  left: 0;
  right: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &-wrapper {
    background-color: var(--plt-text-primary);
    padding: var(--plt-space-200) var(--plt-space-300);
    box-shadow: 0px 8px 16px -4px #17171740;
    border-radius: var(--plt-space-300);
    gap: var(--plt-space-500);
  }

  &-button-wrapper {
    gap: var(--plt-space-200);
  }
}
